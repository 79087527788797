<template>
  <section class="section section--pouziti">
    <div class="section-inner">
      <b-container>
        <b-row align-h="center">
          <b-col lg="8">
            <b-row>
              <b-col sm="4">
                <h2 class="text-primary mb-3">
                  Oblasti použití:
                </h2>
                <ul>
                  <li>Mobilní domy</li>
                  <li>Obytné vozy</li>
                  <li>Přívěsy</li>
                  <li>NÁKLADNÍ AUTOMOBILY</li>
                  <li>Motocykl</li>
                  <li>Čluny</li>
                  <li>Stany</li>
                  <li>Markýzy</li>
                  <li>Ráfky</li>
                  <li>Plastový nábytek</li>
                  <li>PVC krytiny</li>
                  <li>Dlaždičky a spáry</li>
                  <li>Kámen / Beton</li>
                </ul>
              </b-col>
              <b-col sm="4">
                <h2 class="text-primary mb-3">
                  Proti:
                </h2>
                <ul>
                  <li><span v-b-tooltip.hover title="Při výrobě gumových těsnění se používají saze, u kterých dochází k vymývání při dešti.">Tmavým stopám po dešti <fa class="text-info" icon="info-circle" /></span></li>
                  <li>Nikotinu</li>
                  <li>Rzi</li>
                  <li>Nečistotám všeho druhu</li>
                  <li>Tukům / Olejům</li>
                  <li>Řasám / Měděnkám</li>
                  <li>Hmyzu</li>
                  <li>Ptačímu trusu</li>
                </ul>
              </b-col>
              <b-col sm="4">
                <h2 class="text-primary mb-3">
                  Použitelné pro:
                </h2>
                <ul>
                  <li><span v-b-tooltip.hover title="Technologie plastu">GFK <fa class="text-info" icon="info-circle" /></span></li>
                  <li>Plexisklo</li>
                  <li>Hliník</li>
                  <li>Barvy</li>
                  <li>Nerezovou ocel</li>
                  <li>Chrom</li>
                  <li>Plachtový materiál</li>
                </ul>
              </b-col>
            </b-row>
            <p class="mt-3 mb-5">
              UPOZORNĚNÍ:<br> Při prvním použití čističe <br>zkuste to na nenápadném místě.
            </p>

            <h2 class="text-primary mb-3">
              Dávkování:
            </h2>
            <p>
              Pro rychlé čištění nastříkejte přímo z láhve, nechte několik
              sekund působit a setřete. <br>Ředění až 1:10 <br>Lze použít na všechny povrchy odolné vůči alkáliím. <br>Složení:
              &lt; 5 % fosfonáty, neiontové povrchově aktivní látky, kationtové povrchově aktivní látky
            </p>
            <p><strong>Udržujte mimo dosah dětí!</strong></p>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </section>
</template>

<script>

export default {
  name: 'PouzitiSection'
}
</script>

<style lang="scss">
@import "~assets/scss/vars/initial";
@import "~assets/scss/sections/pouziti-section";
</style>
